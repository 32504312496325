import {
    EeviDeviceEditAction,
    EeviDeviceEditorPluginProps,
    EeviDeviceEditorProperties
} from "./components/eevi_device_editor";
import {EeviPluginProps} from "../components/eevi_plugin";
import {
    MedicalAlarmEditor,
    //PhoneDeviceEditor //Removed in DCE-1602
} from "../../../smpers/web/src/components/default_device_editor";
import {EeviErrorState, EeviStandardState} from "../components/eevi_standard_state";
import {deepCopy, strEnum} from "../components/eevi_transform";
import {EeviApi} from "../components/eevi_api";
import deepEqual from "deep-equal";

export type CareGroupCode = string;
export type ServiceLocationCode = string;
export type PlatformCode = "st" | "em" | "jupl" | "gl" | "phone" | "fluid" | "eview";
export type DeviceTypeCode = "Unknown" | "Gateway" | "Watch" | "Mobile Pendant" | "Pendant" | "Hub" | "Motion Sensor" |
    "Power Sensor" | "Bed Sensor" | "Mobile" | "Land Line" | "Open/Close Sensor" | "RTLS Beacon";

export const ResponseGroupType = strEnum(
    "Alarm Response",
    "Emergency Response",
    // "Bed Exit" ,
    // "Bed Entry" ,
    // "Not Returned To Bed" ,
    // "Door Open" ,
    // "Smoke Alarm" ,
    // "Device Fault" ,
    // "System Fault" ,
    // "Inactivity" ,
    // "Not Checked In" ,
    "Tech Support"
);

export type ResponseGroupType = Omit<keyof typeof ResponseGroupType, "[Symbol.iterator]">;

export const EventType = strEnum(
    "BED_EXIT",
    "BED_ENTRY",
    "DOOR_OPEN",
    "SMOKE_ALARM",
    "PENDANT_BUTTON_PUSH",
    "GATEWAY_BUTTON_PUSH",
    "GATEWAY_DTMF_BUTTON_PUSH",
    "VOICE_ALARM_RAISED",
    "DEVICE_FAULT",
    "NOT_RETURNED_TO_BED",
    "INACTIVITY",
    "NOT_CHECKED_IN",
    "SYSTEM_FAULT",
    "ASK_ASSISTANCE",
    "RAISE_EMERGENCY",
    "FALL_DETECTED",
    "STAFF_DURESS",
    "OUT_OF_AREA",
    "LOCATION_ENTRY",
    "SOS_PENDANT_HELP",
    "ELOPEMENT",
);

export type EventType = Omit<keyof typeof EventType, "[Symbol.iterator]">;

export const EventState = strEnum(
    "OPEN", "TAKEN", "TAKEN_ASSISTANCE", "TAKEN_EMERGENCY", "TAKEN_ASSISTANCE_EMERGENCY", "COMPLETED"
);
export type EventState = string & Omit<keyof typeof EventState, "[Symbol.iterator]">;

export const AssistanceRequestType = strEnum(
    "A_PAIR_OF_HANDS", "LIFTER", "OTHER"
);
export type AssistanceRequestType = Omit<keyof typeof AssistanceRequestType, "[Symbol.iterator]">;

export interface PlatformTrait {
    value: PlatformCode
}

export interface PlatformTraits {
    values: PlatformCode[]
}

export interface DeviceTypeTrait {
    value: DeviceTypeCode
}

export interface ServiceLocationTrait {
    value: "Common Area" | "Home Care"
}

export interface CareGroup {
    careGroupCode: CareGroupCode;
    careGroupName: string;
    defaultTimeZoneLabel: string;
    carerLoginExpiryHours: number;
    customData: any;
    serviceLocationPrefix?: string;
    devicePlatforms: PlatformTraits;
}

export interface Carer {
    carerId: string;
    careGroupCode: string;
    carerName: string;
    email: string;
    contactPhoneNumber: string;
    contactSmsNumber: string;
    customData: object;
    role?:string;
    villageNames?: string[];
    loading?: boolean;
    lastLoginTime?: string;
    isOnline?: string;
}

export interface Device {
    deviceId: string;
    platform: PlatformTrait;
    deviceType: DeviceTypeTrait;
    deviceName: string;
    serviceLocationCode?: ServiceLocationCode;
    careGroupCode?: CareGroupCode;
    residentId?: string;
    model?: string;
    phoneNumber?: string;
    capabilities: string[];
    customData: object;

    [propName: string]: any;

    loading?: boolean;
}

export interface ServiceLocation {
    serviceLocationCode: ServiceLocationCode;
    careGroupCode: CareGroupCode;
    traits: ServiceLocationTrait[];
    timeZoneLabel: string;
    customData: object;
    loading?: boolean;
    showDevices?: boolean;
}

export interface AddDeviceToPlatform {
    serviceLocation?: ServiceLocation;
    platformCode?: PlatformCode;
}

export interface ServiceLocationDevices {
    serviceLocation: ServiceLocation;
    devices: Device[];
}

export class EditItem<T extends { [prop: string]: any }> {
    item?: T;
    copyOfOriginal?: T;
    loading: boolean = false;

    constructor(item: T | undefined = undefined) {
        if (item) {
            this.item = item;
            this.copyOfOriginal = deepCopy(item);
        }
    }

    cancelEdit(): void {
        if (this.item && this.copyOfOriginal) {
            for (const prop of Object.getOwnPropertyNames(this.item)) {
                delete this.item[prop];
            }
            Object.assign(this.item, this.copyOfOriginal);
            this.item = undefined;
            this.copyOfOriginal = undefined;
        }
    }

    hasChanged(): boolean {
        return !deepEqual(this.item, this.copyOfOriginal);
    }
}


export type CareGroupServiceLocations = Map<CareGroupCode, "loading" | ServiceLocation[]>;
export type ResponseGroupCarers = Map<ResponseGroupType, "loading" | Carer[]>;
export type CareGroupCarers = Map<CareGroupCode, ResponseGroupCarers>;


export interface DevicePlatform {
    platformCode: PlatformCode;
    devices: Device[];
}

export interface AccessoryFunction {
    serialNumber: string;
    accessoryId: string;
    eventType: EventType;
    readOnly: boolean;
    lastContactTime: string;
}

function platformCompare(lhs: DevicePlatform, rhs: DevicePlatform) {
    function platformOrder(platform: DevicePlatform) {
        const code = platform.platformCode;
        if (code === "jupl") {
            return 'aaa';
        } else if (code === "phone") {
            return 'bbb';
        } else if (code === "st") {
            return 'ccc';
        } else {
            return 'zzz' + code;
        }
    }

    return platformOrder(lhs).localeCompare(platformOrder(rhs))
}

function deviceCompare(lhs: Device, rhs: Device) {
    function deviceOrder(device: Device) {
        if (device.deviceId === undefined) {
            return '';
        } else {
            return `${device.deviceType.value}.${device.deviceId || ''}`;
        }
    }

    return deviceOrder(lhs).localeCompare(deviceOrder(rhs));
}

// PlatformsLoading can be in 3 states (different types)
// - undefined means we haven't looked this up before
// - "loading" means we're in the process of getting it from the database
// - otherwise it's a Map of platform type to devices of that type
type PlatformsLoading = Map<PlatformCode, DevicePlatform> | undefined | "loading";

export class DevicePlatforms {
    private tree = new Map<CareGroupCode, Map<ServiceLocationCode, PlatformsLoading>>();

    getOrCreateDevicePlatform(
        carerGroupCode: CareGroupCode,
        serviceLocationCode: ServiceLocationCode,
        platformCode: PlatformCode,
        editDevice?: Device
    ): DevicePlatform {
        let platforms = this.getPlatformsLoadingForLocation(carerGroupCode, serviceLocationCode);
        if (!(platforms instanceof Map)) {
            // if this is the 1st device, change state from undefined or "loading" to a map of devices by platform
            platforms = new Map();
            this.getServiceLocations(carerGroupCode).set(serviceLocationCode, platforms);
        }
        const devicePlatform: DevicePlatform = platforms.get(platformCode) || {
            platformCode: platformCode,
            devices: []
        };
        platforms.set(devicePlatform.platformCode, devicePlatform);
        if (editDevice &&
            devicePlatform.devices.findIndex((d) => d.deviceId === editDevice.deviceId) === -1) {
            devicePlatform.devices.push(editDevice);
            devicePlatform.devices.sort(deviceCompare);
        }
        return devicePlatform;

    }

    /**
     * If devices is empty list must specify platformCode to allow insert a device for the platform.
     */
    addDevices(
        carerGroupCode: CareGroupCode,
        serviceLocationCode: ServiceLocationCode,
        devices: Device[],
        editDevice: Device | undefined,
        addDeviceToPLatform: AddDeviceToPlatform | undefined
    ): void {
        function platformIsBeingEdited(platformCode: "st" | "em" | "jupl" | "gl" | "phone" | "fluid" | "eview") {
            return (
                editDevice &&
                [serviceLocationCode, undefined].includes(editDevice.serviceLocationCode) &&
                editDevice.platform.value === platformCode
            ) || (
                addDeviceToPLatform &&
                addDeviceToPLatform.serviceLocation &&
                addDeviceToPLatform.serviceLocation.serviceLocationCode === serviceLocationCode &&
                addDeviceToPLatform.platformCode === platformCode
            );
        }

        function getPlatformDevicesMapUpdate() {
            const updatesToPlatformMap = new Map<PlatformCode, Device[]>();
            for (const device of devices) {
                if (!platformIsBeingEdited(device.platform.value)) {
                    const platformDeviceList = updatesToPlatformMap.get(device.platform.value) || [];
                    updatesToPlatformMap.set(device.platform.value, platformDeviceList);
                    platformDeviceList.push(device);
                }
            }
            return updatesToPlatformMap;
        }

        let existingPlatformMap = this.getPlatformsLoadingForLocation(carerGroupCode, serviceLocationCode);
        if (!(existingPlatformMap instanceof Map)) {
            // if this is the 1st device, change state from undefined or "loading" to a map of devices by platform
            existingPlatformMap = new Map();
            this.getServiceLocations(carerGroupCode).set(serviceLocationCode, existingPlatformMap);
        }

        // clear the existing platforms/devices
        const existingPlatforms = Array.from(existingPlatformMap.keys());
        for (const platformCode of existingPlatforms) {
            if (!platformIsBeingEdited(platformCode)) {
                existingPlatformMap.delete(platformCode);
            }
        }
        // get the new platforms / devices - unsorted
        const updatesToPlatformMap = getPlatformDevicesMapUpdate();

        // and update the existing - note the platform being edited is not touched
        for (const platformCode of updatesToPlatformMap.keys()) {
            const devicePlatform: DevicePlatform = existingPlatformMap.get(platformCode) || {
                platformCode: platformCode,
                devices: []
            } as DevicePlatform;
            existingPlatformMap.set(devicePlatform.platformCode, devicePlatform)
            devicePlatform.devices = updatesToPlatformMap.get(platformCode)!;
            devicePlatform.devices.sort(deviceCompare);
        }
    }


    sortDevices(careGroupCode: CareGroupCode,
                serviceLocationCode: ServiceLocationCode,
                platformCode: PlatformCode): void {
        const platforms = this.getDevicePlatforms(careGroupCode, serviceLocationCode);
        if (platforms && platforms !== "loading") {
            const platform = platforms.find((p) => p.platformCode === platformCode);
            if (platform) {
                platform.devices.sort(deviceCompare);
            }
        }
    }

    removeDevice(device: Device): void {
        const platforms = this.getDevicePlatforms(device.careGroupCode!, device.serviceLocationCode!);
        if (platforms && platforms !== "loading") {
            const platform = platforms.find((p) => p.platformCode === device.platform.value);
            if (platform) {
                platform.devices = platform.devices.filter(d => d !== device);
            }
        }
    }


    getDevicePlatforms(carerGroupCode: CareGroupCode,
                       serviceLocationCode: ServiceLocationCode): undefined | "loading" | DevicePlatform[] {
        const loadingDevices = this.getPlatformsLoadingForLocation(carerGroupCode, serviceLocationCode);
        if (!loadingDevices || loadingDevices === "loading") {
            return loadingDevices;
        } else {
            return Array
                .from(loadingDevices.values())
                .sort(platformCompare);
        }
    }

    clearDevicePlatforms(carerGroupCode: CareGroupCode,
                         serviceLocationCode: ServiceLocationCode): void {
        this.getServiceLocations(carerGroupCode).set(serviceLocationCode, undefined);
    }

    setDevicePlatformsLoading(carerGroupCode: CareGroupCode,
                              serviceLocationCode: ServiceLocationCode): void {
        this.getServiceLocations(carerGroupCode).set(serviceLocationCode, "loading");
    }


    /**
     * carer group code maps to service location codes maps to platform codes maps to devices
     */
    private getPlatformsLoadingForLocation(
        carerGroupCode: CareGroupCode,
        serviceLocationCode: ServiceLocationCode
    ): PlatformsLoading {
        const serviceLocations = this.getServiceLocations(carerGroupCode);
        return serviceLocations.get(serviceLocationCode);
    }


    private getServiceLocations(carerGroupCode: CareGroupCode) {
        let serviceLocations = this.tree.get(carerGroupCode);
        if (!serviceLocations) {
            serviceLocations = new Map();
            this.tree.set(carerGroupCode, serviceLocations);
        }
        return serviceLocations;
    }
}

/**
 * Shared global state
 */
export class EeviCoreModel {
    // Note this is tied into the state.error that is part of the form state from legacy code.
    // Errors from EeviApi will end up in the top level form.state.error as well as here.
    // error: any;

    careGroups: CareGroup[] | "loading" | undefined;
    careGroupServiceLocations: CareGroupServiceLocations = new Map();
    careGroupServiceLocationSearchText: Map<CareGroupCode, string> = new Map();
    selectedCareGroupCode?: CareGroupCode;
    selectedResponseGroupType?: ResponseGroupType;

    editServiceLocation = new EditItem<ServiceLocation>();
    careGroupCarers: CareGroupCarers = new Map();
    editCarer = new EditItem<Carer>();

    serviceLocationPlatformForDeviceInsert: AddDeviceToPlatform = {};
    editDevice = new EditItem<Device>();
    devicePlatforms = new DevicePlatforms();
    deviceEditorProps: EeviDeviceEditorPluginProps | undefined;
    internalDeviceEditorProps = new EeviDeviceEditorPluginProps([
        ["jupl", {url: undefined, plugin: MedicalAlarmEditor, name: "Eevi Gateway"}],
        //["phone", {url: undefined, plugin: PhoneDeviceEditor, name: "Phone or Watch"}],   //Removed in DCE-1602
    ]);
}


/**
 * These functions build and interrogate the Eevi Core Model class above (and load on demand from the API/Rest).
 * Set refresh=true to force reload from the database.
 * If updates are made to EeviCoreModel directly rather than through EeviCoreAccess, the UI can be updated by calling
 * onModelUpdate().
 */
export interface EeviCoreAccess extends EeviErrorState {
    // care groups
    getCareGroups(refresh?: boolean): "loading" | CareGroup[];

    selectCareGroup(careGroupCode?: CareGroupCode): void;

    getSelectedCareGroup(): CareGroupCode | undefined;

    selectResponseGroupType(groupType?: ResponseGroupType): void;

    getSelectedResponseGroupType(): ResponseGroupType | undefined;

    // carers
    carerBeingEdited: Carer | undefined;

    getCarers(careGroupCode: CareGroupCode, refresh?: boolean, clusterSize?: number): "loading" | Carer[];

    insertCarer(careGroupCode: CareGroupCode): void;

    saveCarer(carer: Carer, carerIndex: number, loading: boolean): void;

    removeCarer(carer: Carer): void;

    editCarer(carer: Carer): void;

    logoutCarer(carer: Carer, callback: any): void;

    cancelEditCarer(): void;

    // service locations
    serviceLocationBeingEdited: ServiceLocation | undefined;

    getCareGroupServiceLocations(
        careGroupCode: CareGroupCode, refresh?: boolean, searchText?: string
    ): "loading" | ServiceLocation[];

    editServiceLocation(serviceLocation: ServiceLocation): void;

    saveServiceLocation(clearOccupancy: boolean): void;

    clearServiceLocation(): void;

    setServiceLocationLoading(loading: boolean): void;

    serviceLocationIsLoading(): boolean;

    // devices
    deviceBeingEdited: Device | undefined;
    serviceLocationPlatformForDeviceInsert: AddDeviceToPlatform;

    setServiceLocationPlatformForDeviceInsert(platform: PlatformCode, serviceLocation: ServiceLocation): void;

    clearServiceLocationPlatformForDeviceInsert(): void;

    getServiceLocationDevices(
        careGroupCode: CareGroupCode, serviceLocationCode: ServiceLocationCode, refresh?: boolean): "loading" | Device[];

    disabledDeviceEdits(): boolean;

    newDevice(device: Device): void;  // not saved just added to client model for editing
    saveDevice(): void; // vc platforms only
    removeDevice(): void;

    deviceHasChanged(): boolean;

    editDevice(device: Device): void;
    editDeviceAndAccessories(device: Device): void;

    cancelEditDevice(): void;

    setDeviceLoading(loading: boolean): void;

    deviceIsLoading(): boolean;

    refreshEditDevice(device: Device, updated: Partial<Device>): void; // update device details without re-fetching

    // device platforms
    getServiceLocationDevicePlatforms(
        serviceLocation: ServiceLocation,
        refresh?: boolean
    ): "loading" | DevicePlatform[];

    // device editors / plugins
    getAllDeviceEditorProps(refresh?: boolean): EeviDeviceEditorPluginProps;

    getDeviceEditorProps(platform: DevicePlatform,
                         serviceLocation: ServiceLocation,
                         careGroup: CareGroup,
                         action: EeviDeviceEditAction,
                         refresh?: boolean): EeviPluginProps<EeviDeviceEditorProperties>;

    // call this to update the GUI after the model has been directly changed
    onModelUpdate(): void;

    // show a custom page embedded in a pop-up window
    showVendorSettings(deviceName: string, url: string): void;

    closeVendorSettings(): void;

    eeviApi: EeviApi<EeviStandardState>;
}

export interface EeviEventAccess extends EeviErrorState {
    // event
    completeEvent(eventId: string, buttonId: string): void;
}
