import {EeviCollapseHeading} from "../../../../common/web_common/components/eevi_collapse_heading";
import {
    Button,
    Container,
    React,
    Table,
    Input,
    UncontrolledTooltip, InputGroup, InputGroupAddon
} from "../../../../common/web_common/components/eevi_react_exports";
import {
    CareGroup,
    DevicePlatform,
    PlatformCode,
    ServiceLocation
} from "../../../../common/web_common/core/eevi_core_data";
import {EeviCoreContext} from "../../../../common/web_common/core/components/eevi_core_context";
import {EeviLoading} from "../../../../common/web_common/components/eevi_loading";
import {boldItalic} from "../../../../common/web_common/components/eevi_style";
import {isBlankString, setEeviUid, setInputFocus} from "../../../../common/web_common/components/eevi_util";
import {EeviDropDown, EeviDropDownMenuItem} from "../../../../common/web_common/components/eevi_drop_down";
import {
    EeviDeviceEditAction, EeviDeviceEditorPlugin
} from "../../../../common/web_common/core/components/eevi_device_editor";
import {Results} from "./results";
import {VillageLocation} from "./default_device_editor";
import {dynamicSubclass} from "../../../../common/web_common/components/eevi_transform";
import {eeviGlobal} from "../../../../common/web_common/components/eevi_context";


export function ServiceLocationCareGroupContent(props: { careGroup: CareGroup }) {
    eeviGlobal.logRender('ServiceLocationsForm', props.careGroup.careGroupName);
    const {access} = React.useContext(EeviCoreContext);
    const group = props.careGroup;
    const collapsed = (group: CareGroup) => group.careGroupCode !== access!.getSelectedCareGroup();

    return <EeviCollapseHeading
        onChange={(collapse) => access!.selectCareGroup(collapse ? undefined : group.careGroupCode)}
        defaultToCollapsed={collapsed(group)}
        key={setEeviUid(group, "hdng")}
        ikey={setEeviUid(group, "ihdng")}
        heading={props.careGroup.careGroupName}>
        {
            !collapsed(group) &&
            <ListCareGroupServiceLocations careGroup={props.careGroup} key={setEeviUid(group, "lshdng")}/>
        }
    </EeviCollapseHeading>;

}

interface SearchServiceLocationProps {
    loading: boolean;
    onSearch(text: string): void;
}


function SearchServiceLocations(props: SearchServiceLocationProps) {
    const [searchText, setSearchText] = React.useState('');
    const {access} = React.useContext(EeviCoreContext);

    function clearSearch() {
        setSearchText('');
        props.onSearch('');
    }

    function applySearch(){
        access!.cancelEditDevice();
        props.onSearch(searchText)
    }

    return <InputGroup
        className="ml-2 mb-3"
        hidden={props.loading}>
        <Input
            value={searchText}
            onChange={(e) => {
                setSearchText(e.target.value);
            }}
            onKeyDown={(e) => {
                if (e.key == 'Enter') applySearch();
            }}
        />
        <InputGroupAddon addonType="append" hidden={searchText.length == 0}>
            <Button className="btn-outline-secondary" color='#495057' onClick={clearSearch}>
                <i className="fas fa-times"/>
            </Button>
        </InputGroupAddon>
        <InputGroupAddon className="mr-2" addonType="append">
            <Button
                className="btn-primary"
                onClick={applySearch}>search</Button>
        </InputGroupAddon>
    </InputGroup>;
}


function ListCareGroupServiceLocations(props: { careGroup: CareGroup }) {
    const {access} = React.useContext(EeviCoreContext);
    const [searchText, setSearchText] = React.useState('');
    const locations = access!.getCareGroupServiceLocations(props.careGroup.careGroupCode, false, searchText);
    const group = props.careGroup;


    return <>
        <SearchServiceLocations
            onSearch={setSearchText}
            key={props.careGroup.careGroupCode + ":searchcgsl"}
            loading={locations === "loading"}/>
        <Results key={setEeviUid(group, "res1")} items={locations} none="No residences..." render={
            loc => <ServiceLocationContent
                serviceLocation={loc}
                careGroup={props.careGroup}
                key={setEeviUid(loc, "lslc:")}
                forceShowDevices={searchText !== ''}
            />

        }/>
    </>;
}

function ServiceLocationResidents(
    props: {
        serviceLocation: ServiceLocation,
        careGroup: CareGroup,
        editMode: boolean,
        onDevices: { (): void }
    }) {
    const location = dynamicSubclass(props.serviceLocation, VillageLocation);
    const {access, model} = React.useContext(EeviCoreContext);
    const editMode = access!.serviceLocationBeingEdited === location;
    const [stateCount, setStateCount] = React.useState(0);
    const [confirming, setConfirming] = React.useState(false);

    function forceUpdate() {
        setStateCount(stateCount + 1);
    }

    function renderDisplay() {
        return <Table borderless className="m-0" key={setEeviUid(location, "display_resident")}>
            <tbody>
            <tr>
                <td className="keep-lines pb-0 pt-1">
                    <h5>{location.residentNames || 'Not occupied'}</h5>
                    {location.serviceLocationCode}
                </td>
                <td className="text-right pr-0 pb-2 pt-1">
                    <div>
                        <Button
                            disabled={access!.disabledDeviceEdits()}
                            size="sm" color="outline-secondary"
                            className={editMode ? "invisible" : "eevi_edit_button"}
                            onClick={() => access!.editServiceLocation(location)}>
                            resident
                        </Button>
                    </div>
                    <div className="mt-2">
                        <Button
                            disabled={access!.disabledDeviceEdits()}
                            size="sm" color="outline-secondary"
                            className={editMode ? "invisible" : "eevi_edit_button"}
                            onClick={props.onDevices}>
                            devices
                        </Button>
                    </div>

                </td>
            </tr>
            </tbody>
        </Table>;
    }

    function cancelEdit() {
        model!.editServiceLocation.cancelEdit();
        access!.onModelUpdate();
    }

    // innerRef={location.residentNames ? undefined : setInputFocus}

    function onConfirmed() {
        setConfirming(false);
        access!.clearServiceLocation();
    }

    function renderEdit() {
        if (access!.serviceLocationIsLoading()) {
            return <EeviLoading/>;
        }
        return <div className="card shadow p-4 border" key={setEeviUid(location, "edit_resident")}>
            {
                confirming &&
                <div className="card shadow border-warning">
                    <h2 className="modal-header">Clear resident details?</h2>
                    <div className="p-4 text-justify">
                        {
                            `This will set ${location.serviceLocationCode}` +
                            " to unoccupied, clear emergency notes and remove associated personal devices. " +
                            "Medical alarms and landline phones will remain associated with the residence."
                        }
                    </div>
                    <div className="p-2">
                        <Button className="m-2" onClick={onConfirmed} color="primary">ok</Button>
                        <Button className="m-2" color="secondary" onClick={() => setConfirming(false)}>
                            cancel
                        </Button>
                    </div>
                </div>

            }
            {
                !confirming && <>
                    <div style={boldItalic}>Resident names, notes relevant to a medical alarm...</div>
                    <Input
                        id={setEeviUid(location, "resident_names")}
                        key={setEeviUid(location, "resident_names")}
                        type="text"
                        className="mb-1 eevi_edit_text"
                        innerRef={setInputFocus}
                        value={location.residentNames || ''}
                        onChange={
                            (e) => {
                                // e.preventDefault();
                                location.residentNames = e.target.value;
                                forceUpdate();
                            }
                        }
                        placeholder="Resident name(s)"/>
                    <UncontrolledTooltip target={setEeviUid(location, "resident_names")}
                                         delay={400}>current resident</UncontrolledTooltip>
                    <Input
                        id={setEeviUid(location, "resident_notes")}
                        key={setEeviUid(location, "resident_notes")}
                        type="textarea"
                        className="mb-1 eevi_edit_text"
                        value={location.smsMessage || ''}
                        onChange={
                            (e) => {
                                // e.preventDefault();
                                location.smsMessage = e.target.value;
                                forceUpdate();
                            }
                        }
                        placeholder="Notes to SMS carers in an emergency"/>
                    <UncontrolledTooltip target={setEeviUid(location, "resident_notes")}
                                         delay={400}>Current resident
                    </UncontrolledTooltip>
                    <div className="text-right">
                        <Button
                            onClick={() => access!.saveServiceLocation(false)}
                            size="sm"
                            color="outline-secondary"
                            id="save"
                            className="ml-1 eevi_edit_button"
                            disabled={isBlankString(location.residentNames)}>
                            <span>save</span>
                        </Button>
                        <Button
                            onClick={cancelEdit}
                            size="sm" color="outline-secondary"
                            className="ml-1 eevi_edit_button"
                            id="cancel">cancel</Button>
                        <Button
                            onClick={() => setConfirming(true)}
                            size="sm" color="outline-secondary"
                            className="ml-1 eevi_edit_button"
                            id="clear">no resident</Button>
                        <UncontrolledTooltip target="save" delay={400}>update database</UncontrolledTooltip>
                        <UncontrolledTooltip target="cancel" delay={400}>return without
                            updating</UncontrolledTooltip>
                        {<UncontrolledTooltip target="clear" delay={400}>set to unoccupied</UncontrolledTooltip>}
                    </div>
                </>
            }
        </div>;

    }

    return editMode ? (model!.editServiceLocation.loading ? <EeviLoading/> : renderEdit()) : renderDisplay();
}

/**
 * For a particular platform (see PlatformTraits) list the devices of that type
 */
function ListPlatformDevices(
    props: {
        platform: DevicePlatform,
        serviceLocation: ServiceLocation,
        careGroup: CareGroup,
        action: EeviDeviceEditAction
    }) {
    const {access} = React.useContext(EeviCoreContext);
    const pluginProps = access!.getDeviceEditorProps(
        props.platform,
        props.serviceLocation,
        props.careGroup,
        props.action
    );
    return <EeviDeviceEditorPlugin {...pluginProps} />;
}

function ListServiceLocationDevices(props: { serviceLocation: ServiceLocation, careGroup: CareGroup }) {
    eeviGlobal.logRender('ListServiceLocationDevices', props.serviceLocation.serviceLocationCode);
    const {access} = React.useContext(EeviCoreContext);
    const loc = props.serviceLocation;
    const serviceLocationPlatformForDeviceInsert = access!.serviceLocationPlatformForDeviceInsert;

    function action(platformCode: PlatformCode): EeviDeviceEditAction {
        if (loc === serviceLocationPlatformForDeviceInsert.serviceLocation && platformCode === serviceLocationPlatformForDeviceInsert.platformCode) {
            return EeviDeviceEditAction.Insert;
        } else {
            return EeviDeviceEditAction.View;
        }
    }

    const platforms = access!.getServiceLocationDevicePlatforms(loc);
    return <Results items={platforms} none="No devices..." render={
        (p, index) => <>
            {index > 0 && <hr className="mb-1"/>}
            <ListPlatformDevices {...props}
                                 platform={p}
                                 action={action(p.platformCode)}
                                 key={setEeviUid(p, 'asqw')}/>
        </>
    }/>;
}


function AddDeviceButton(props: { serviceLocation: ServiceLocation, careGroup: CareGroup }) {
    const location = props.serviceLocation;
    const {model, access} = React.useContext(EeviCoreContext);
    const editMode = (location === model!.editServiceLocation.item);
    const careGroupPlatformCodes = props.careGroup.devicePlatforms.values;

    function insertDeviceForPlatform(platformCode: string) {
        access!.setServiceLocationPlatformForDeviceInsert(platformCode as PlatformCode, location);
    }

    function addDeviceMenuItems(): EeviDropDownMenuItem[] {
        const menuItems: EeviDropDownMenuItem[] = [];
        for (let [platformCode, props] of access!.getAllDeviceEditorProps().entries()) {
            if (!careGroupPlatformCodes.includes(platformCode)) {
                continue;
            }
            menuItems.push({
                onClick: () => insertDeviceForPlatform(platformCode),
                label: props.name,
                key: `admi:${platformCode}`,
                isBold: false,
                isIndented: false,
                menuItemClass: "btn-sm"
            });
        }
        return menuItems;
    }


    return <div className="text-right">
        <EeviDropDown
            disabled={access!.disabledDeviceEdits()}
            key={setEeviUid(location, "sevdd")}
            className="ml-md-3 ml-sm-2 mr-md-0 mr-sm-0"
            menuButtonContent="add device"
            menuButtonClass={editMode ? "invisible" :
                "eevi_add_button btn-outline-secondary btn-sm"}
            noDefaultButtonStyling={true}
            menuItems={addDeviceMenuItems()}
        />
    </div>;
}

interface ServiceLocationContentProps {
    serviceLocation: ServiceLocation;
    careGroup: CareGroup;
    forceShowDevices: boolean;
}

function ServiceLocationContent(props: ServiceLocationContentProps) {
    eeviGlobal.logRender('ServiceLocationContent', props.serviceLocation.serviceLocationCode);
    const location = props.serviceLocation;
    const careGroup = props.careGroup;
    const {model} = React.useContext(EeviCoreContext);
    const editMode = (location === model!.editServiceLocation.item);
    const [stateCount, setStateCount] = React.useState(0);

    if (props.forceShowDevices) {
        location.showDevices = true;
    }

    function toggleShowDevices() {
        location.showDevices = !location.showDevices;
        setStateCount(stateCount + 1);
    }


    return <div key={setEeviUid(location, "locct")}>
        <div className="border p-1 mb-2 ml-2">
            <ServiceLocationResidents
                onDevices={toggleShowDevices}
                serviceLocation={location}
                careGroup={props.careGroup}
                key={setEeviUid(location, "SLRS11")}
                editMode={editMode}/>
            <div hidden={!location.showDevices}>
                <div className="float-left" style={boldItalic}>devices</div>
                <AddDeviceButton
                    careGroup={careGroup} serviceLocation={location} key={setEeviUid(location, "adD")}/>
                <div className="border-top">
                    <Container className="mb-0 mt-0">
                        <ListServiceLocationDevices {...props} key={setEeviUid(location, "adDlsl")}/>
                    </Container>
                </div>
            </div>
        </div>
    </div>;
}


